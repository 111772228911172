<template>
  <div>
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <div class="d-flex">
          <div>
            <b-button to="/user/add" variant="primary"
              > Add User</b-button
            >
          </div>
          <div v-if="itemsToTakeAction.length > 0" class="ml-1">
            <b-button @click="deleteUsers" variant="danger"
              >Delete Selected</b-button
            >
          </div>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="users"
        :isLoading="isDataLoading"
        @on-select-all="onSelectAll"
        @on-row-click="onRowClick"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'newsDate'">
            <b-badge variant="light-success">
              {{ getFormattedData(props.row.newsDate) }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'name'">
             <router-link :to="`/user/update/${props.row._id}`">
              {{ props.row.name}}
            </router-link>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item :to="`/user/update/${props.row._id}`">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteUsers({_id:props.row._id})">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10', '15']"
                class="mx-1"
              />
              <span class="text-nowrap"> of {{ totalPages }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="pageNumber"
                :total-rows="totalPages"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import moment from "moment-timezone";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
  },
  data() {
    return {
      users: [],
      pageLength: 15,
      pageNumber: 1,
      totalPages: 0,
      itemsToTakeAction: [],
      isDataLoading: true,
      dir: false,
      columns: [
        {
          label: "User",
          field: "name",
        },
        {
          label: "Role",
          field: "role",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Account Type",
          field: "accountType",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
    };
  },
  methods: {
    getUsers() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/org/user/all?searchTerm=${this.searchTerm}&pageNumber=${this.pageNumber}&perPage=${this.pageLength}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.totalPages=response.data[0].metadata.total;
          this.users = response.data[0].users;
          this.isDataLoading = false;
        })
        .catch((error) => {
          this.isDataLoading = false;
          console.log(error.response);
        });
    },
    getFormattedData(date) {
      return moment(date).tz("Asia/Calcutta").format("DD-MM-YYYY");
    },
    deleteUsers(props) {
      if(props._id){
        this.itemsToTakeAction.push( props._id )
      }
      if (
        confirm("Are you sure want to delete the selected Users?")
      ) {
        this.isDataLoading = true;
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/org/user/delete`,
            {
              users: this.itemsToTakeAction,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < this.itemsToTakeAction.length; i++) {
              const item = this.itemsToTakeAction[i];
              const index = this.users.findIndex((user) => {
                return user._id == item;
              });
              this.users.splice(index, 1);
            }
            this.itemsToTakeAction = [];
            this.isDataLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.isDataLoading = false;
          });
      }
    },
    onSelectAll(params) {
      const selectedItems = params.selectedRows.map((item) => {
        return item._id;
      });
      this.itemsToTakeAction = selectedItems;
    },
    onRowClick(params) {
      const item = params.row._id;
      if (!params.selected) {
        const itemIndex = this.itemsToTakeAction.findIndex((itemObj) => {
          return itemObj == item;
        });
        if (itemIndex > -1) {
          this.itemsToTakeAction.splice(itemIndex, 1);
        }
      } else {
        this.itemsToTakeAction.push(item);
      }
    },
  },
  created() {
    this.getUsers();
  },
  watch: {
    pageLength: function(newValue, oldValue) {
      console.log(newValue, oldValue);
      if (newValue !== oldValue) {
        this.getUsers();
      }
    },
    pageNumber: function(newValue, oldValue) {
      console.log(newValue, oldValue);
      if (newValue !== oldValue) {
        this.getUsers();
      }
    },
    searchTerm: function(newValue, oldValue) {
      console.log(newValue, oldValue);
      if (newValue !== oldValue) {
        this.getUsers();
      }
    }
  }
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
